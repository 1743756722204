import React from 'react';
import { RegisterForm } from "../../components/session";
import { Link } from 'gatsby';
import queryString from 'query-string'
import { acceptOrgInvite } from "../../api";
import { navigateTo } from "gatsby-link"
import * as Paths from '../../constants/Paths'
import LayoutA from "../../layouts/LayoutA";

class AcceptInvite extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      success: false,
      emailInQuery: null,
      newUserMode: false,
      orgId: null,
      error: null
    };
  }

  componentDidMount() {

    var query = queryString.parse(window.location.search);

    if (query.email) {
      this.setState({
        emailInQuery: atob(query.email),
        newUserMode: true,
        inviteToken: query.token
      });
    } else {
      this.acceptToken(query.token);
    }

  }

  render() {

    const { emailInQuery, inviteToken, newUserMode, error } = this.state;

    return (
      <LayoutA>
        <div className="LoginTemplate">

        {error ? (
          <div className="LoginTemplate-floatingform">
            <div className="f2 s3">There was an error :( </div>
            <div className="f4 s3">{error}</div>
            <Link className="f3" to={Paths.LOGIN}>
              <span className="sr2">Please Login to join your org.</span>
              <span className="pt-icon-standard pt-icon-arrow-right" />
            </Link>
          </div>
        ) : newUserMode ? (
          <div className="LoginTemplate-floatingform">
            <div className="f3 t-center f-strong f-subdued s8">Looks like you don't have an account yet... Let's make one before joining the team.</div>
            <div className="login-form s6">
              <RegisterForm hardcodeEmail={emailInQuery}
                            onSuccess={(user) => this.acceptToken(inviteToken) } />
            </div>
            <div className="s1">
              <Link href={Paths.LOGIN} className="f4">
                <span className="bp3-icon-standard bp3-icon-arrow-left" />Back to login</Link>
            </div>
          </div>
        ) : (
          <div>loading...</div>
        )}
        </div>
      </LayoutA>
    );
  }

  acceptToken(token) {
    acceptOrgInvite(token)
      .then(() => {
        navigateTo(Paths.DB_USER_HOME);
      })
      .catch((e) => {
        this.setState({ error: e.message })
      })


  }

}

export default AcceptInvite;